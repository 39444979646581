<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>商品ID：</label>
            <el-input v-model="params.id" placeholder="商品ID"></el-input>
          </el-row>
          <el-row class="search-item">
            <label>商品名称：</label>
            <el-input v-model="params.queryParam" placeholder="商品名称"></el-input>
          </el-row>
          <el-row class="search-item">
            <label>供应酒店：</label>
            <el-select v-model="params.hotelId" placeholder="供应酒店">
              <el-option v-for="item in hotelOpt" :key="item.id" :label="item.hotelName" :value="item.id"></el-option>
            </el-select>
          </el-row>
          <el-button @click="handleQuery(true)" class="bg-gradient" type="primary" icon="el-icon-search">搜索</el-button>
          <el-button @click="handleQuery(false)" icon="el-icon-refresh-right" plain>重置</el-button>
        </el-row>
      </el-row>
      <el-row class="table-box">
        <el-row type="flex" justify="space-between">
          <el-tabs v-model="params.status" @tab-click="handleTab">
            <el-tab-pane v-for="(item, index) in tabs[type]" :label="item.label" :name="item.name" :key="index"></el-tab-pane>
          </el-tabs>
          <el-row class="right-btn" v-if="type === 'list'">
            <el-button class="bg-gradient" @click="handleCreate('create')">创建商品</el-button>
          </el-row>
        </el-row>
        <el-table v-loading="loading" :data="tableData" :stripe="true" border fit>
          <el-table-column type="expand" v-if="type === 'list'">
            <template slot-scope="scope" v-if="scope.row.productSkus">
              <el-descriptions v-for="(item, index) in scope.row.productSkus" :key="index">
                <el-descriptions-item label="套餐名">{{ item.name }}</el-descriptions-item>
                <el-descriptions-item label="售价">{{ item.price }}</el-descriptions-item>
                <el-descriptions-item label="库存">{{ item.quantity }}</el-descriptions-item>
              </el-descriptions>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="商品ID"></el-table-column>
          <el-table-column label="商品图片">
            <template slot-scope="scope"><el-image style="width: 70px" :src="scope.row.image"></el-image></template>
          </el-table-column>
          <el-table-column prop="name" label="商品名称"></el-table-column>
          <el-table-column prop="skuNum" label="套餐数量"></el-table-column>
          <el-table-column prop="hotelName" label="供应酒店"></el-table-column>
          <el-table-column prop="createTime" label="操作时间"></el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              {{ scope.row.status | filterStatus(that) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" v-if="type === 'list'">
            <template slot-scope="scope">
              <el-button @click="handleCreate('look', scope.row.id)" type="text">查看</el-button>
              <el-button type="text" @click="handleCreate('modify', scope.row.id)">编辑</el-button>
              <el-button v-if="scope.row.status === 'SOLD_OUT' || scope.row.status === 'PASS_THE_AUDIT'" type="text" @click="updateStatus(scope.row.id,'PUTAWAY')">上架</el-button>
              <el-button v-if="scope.row.status === 'PUTAWAY'" type="text" @click="updateStatus(scope.row.id,'SOLD_OUT')">
                <el-link type="danger">下架</el-link>
              </el-button>
              <el-button type="text" v-if="scope.row.status !== 'PUTAWAY'" @click="handleDel(scope.row.id)">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作" v-else>
            <template slot-scope="scope">
              <el-button v-if="scope.row.status === 'TO_AUDIT'" type="text" @click="updateStatus(scope.row.id, 'PASS_THE_AUDIT')">通过</el-button>
              <el-button v-if="scope.row.status === 'TO_AUDIT'" type="text" @click="updateStatus(scope.row.id, 'AUDIT_FAILED')">
                <el-link type="danger">拒绝</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
      </el-row>
    </div>
  </div>
</template>
<script>
import { getPage, selectHotel, setStatus, modifyGoods } from '@/api/shop/goods/goods';
import { getDict } from "@/common/js/common";
import { mapState } from 'vuex'
export default {
  name: 'goodList',
  data() {
    return {
      that: this,
      crumbs: new Map([
        ['特惠商城'], ['商品中心'], ['商品列表']
      ]),
      params: {
        id: '',
        queryParam: '',
        srcHotelId: '',
        status: ''
      },
      loading: false,
      tableData: [],
      total: 0,
      limit: 1,
      page: 1,
      hotelOpt: [],
      type: '',
      tabs: {
        list: [
          { label: '全部', name: '' },
          { label: '上架中', name: 'PUTAWAY' },
          { label: '已下架', name: 'SOLD_OUT' }
        ],
        audit: [
          { label: '全部', name: '' },
          { label: '审核中', name: 'TO_AUDIT' },
          { label: '审核未通过', name: 'AUDIT_FAILED' }
        ]
      }
    }
  },
  computed: {
    ...mapState(['dictData', 'hotelInfo'])
  },
  mounted() {
    this.type = this.$route.params.type
    getDict('goods-status')
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.getHotelOpt()
    this.getGoodsList()
  },
  methods: {
    // 获取商品列表
    getGoodsList() {
      const query = `?limit=${ this.limit }&page=${ this.page }`,
            params = { ...this.params, hotelId: this.hotelInfo.id };
      params.status === '0' && (params.status = '');
      const { accountType } = JSON.parse(sessionStorage.getItem('userInfo') || '{}')
      if (accountType === 'PLATFORM' && this.type === 'list') params.notStatus = ['AUDIT_FAILED']
      // if (this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getPage(query, params).then(({ success, total, records }) => {
        if (!success) return;
        this.total = total
        this.tableData = records
      })
    },
    // 获取酒店下拉
    getHotelOpt() {
      selectHotel({}).then(({ success, records }) => {
        if (!success) return;
        this.hotelOpt = records.map(item => ({ id: item.id, hotelName: item.hotelName }))
      })
    },
    // 查询商品
    handleQuery(bool) {
      this.page = 1
      if (bool) return this.getGoodsList()
      for (const k in this.params) this.params[k] = ''
    },
    // 改变每页数
    changePageNum(num) {
      this.limit = num
      this.getGoodsList()
    },
    // 改变当前页
    handleCurrPage(num) {
      this.page = num
      this.getGoodsList()
    },
    // 改变tab栏
    handleTab() {
      this.page = 1
      this.getGoodsList()
    },
    // 修改商品状态
    updateStatus(id, status) {
      this.$confirm(`确定${ this.dictData['goods-status'][status] }`, '提示', {
        confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
      }).then(_ => {
        setStatus({ id, status }).then(({ success }) => {
          if (!success) return;
          this.getGoodsList()
          this.$message({ message: `商品${ this.dictData['goods-status'][status] }成功！`, type: 'success' })
        })
      }).catch(_ => {})
    },
    // 创建商品
    handleCreate(action, id) {
      this.$router.push({ path: '/preferential_mall/goods_create', query: { action, id } })
    },
    // 删除商品
    handleDel(id) {
      this.$confirm('确定删除该商品？', '提示', {
        confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
      }).then(() => {
        modifyGoods({ id }).then(({ success }) => {
          if (!success) return;
          this.getGoodsList()
          this.$message({ message: '删除成功！', type: 'success' })
        })
      })
    }
  },
  filters: {
    filterStatus(val, that) {
      if (val && that.dictData['goods-status']) return that.dictData['goods-status'][val]
    }
  },
  watch: {
    $route(to) {
      if (to.name === 'goodsList') {
        this.type = this.$route.params.type
        this.getGoodsList()
        this.handleQuery(false)
      }
    },
    hotelInfo(newVal, oldVal) {
      oldVal && newVal.id !== oldVal.id && this.getGoodsList()
    }
  }
}
</script>
<style lang="scss" scoped>
.el-descriptions{
  width: 600px; padding: 0 0 0 10px;
}
</style>
